.login-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    max-width: 350px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 20px orange;
    background-color: rgb(187, 125, 11);
    transition: box-shadow 0.2s ease;
}

/* .login-form:hover {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
} */

.login-form-header {
    text-align: center;
    font-size: 2.5em;
    color: black;
    margin-bottom: 20px;
}

.login-form-errors {
    list-style: none;
    padding: 0;
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
    color: white;
    margin-bottom: 10px;
}

.login-form-email, .login-form-password{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
    font-size: 1em;
    color: #333;
}

.login-form-button {
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    background-color: #06660b7c;
    color: #ffffff;
    font-size: 1em;
    cursor: pointer;
    width: 100%;
    margin: 5px 0;
    transition: background-color 0.2s ease;
}

.login-form-input {
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ccc;
    outline: none;
    font-size: 1em;
    transition: border-color 0.2s ease;
}


.login-form-button:hover {
    background-color: #06660bfd;
  }
