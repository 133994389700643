.profile-dropdown {
  position: absolute;
  right: 15px;
  background-color: rgb(187, 125, 11);
  list-style: none;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  padding-left: 10px;
  z-index: 1;
  margin-top: 10px;
  border-radius: 5px;
}

.profile-dropdown > li {
  margin-left: 25px;
  padding: 5px;
  color: rgba(238, 238, 238, 1);
}

.dropdown-buttons{
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.hidden {
  display: none;
}

.nav-sidebar {
  display: flex;
  flex-direction: column;
  height: 99%;
}

.nav-list {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  list-style: none;
  margin-left: 20px;
  width: 80%;
}
.nav-list-top-wrapper,
.nav-list-bottom-wrapper,
.nav-list-middle-wrapper,
.nav-list-playlist-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  align-self: flex-start;
}

.nav-list > ul {
  margin-top: 10px;
}

.nav-list-top-wrapper {
  align-self: center;
}

.nav-list-item {
  width: fit-content;
  display: flex;
  align-self: center;
  justify-self: center;
  column-gap: 5px;
}

.nav-list-item > a {
  text-decoration: none;
  color: rgba(238, 238, 238, 1);
  width: fit-content;
  align-self: center;
  justify-self: center;
}

.nav-list-bottom-wrapper,
.nav-list-playlist-wrapper {
  align-self: flex-start;
  margin-left: 5px;
}

.nav-list-bottom-wrapper > .nav-list-item {
  align-self: flex-start;
}

.nav-list-playlist-wrapper > .nav-list-item {
  align-self: flex-start;
}

.nav-list-playlist-wrapper {
  margin-top: 25px;
}

.nav-list-playlist-wrapper.without-user {
  margin-top: -10px;
}

.user-icon-button {
  background-color: #06660bfd;
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 5px;
  width: 80px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.user-icon-button.small {
  width: 30px;
  column-gap: 0px;
  display: block;
}

.user-icon-button > i {
  color: rgba(238, 238, 238, 1);
}

.dropdown-item {
  color: black;
  margin-top: 5px;
  margin-right: 15px;
}

.nav-list-item-nav-link {
  display: flex;
  align-items: center;
}

.nav-list-item-nav-link > img {
  width: 50px;
  height: 50px;
}

.dropdown-logout {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border: none;
  margin-left: 25px;
  /* left: 10px; */
  border-radius: 20px;
  background-color: #06660b7c;
  color: #ffffff;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.dropdown-logout:hover {
  background-color: #06660bfd;
}

.dropdown-login {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border: none;
  margin-left: 10px;
  /* left: 10px; */
  border-radius: 20px;
  background-color: #06660b7c;
  color: #ffffff;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.dropdown-login:hover {
  background-color: #06660bfd;
}

.dropdown-signup {
  display: flex;
  justify-self: center;
  align-items: center;
  padding: 10px 20px;
  border: none;
  margin-left: 5px;
  margin-right: 5px;
  /* left: 10px; */
  border-radius: 20px;
  background-color: #06660b7c;
  color: #ffffff;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.dropdown-signup:hover {
  background-color: #06660bfd;
}

.nav-list-creators {
  width: 98%;
  margin: 0 auto;
  margin-top: auto;
}

.nav-list-mangoes {
  display: flex;
  justify-content: center;
}

.nav-list-mango-member {
  text-align: center;
  width: auto;
}

.mango-member-name {
  font-size: 1.3vh;
  margin: 8px 0;
}

.nav-list-profile-links {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.vertical-rule {
  border: 1px solid #fff;
  margin: 0 10px;
}
