.outerPlaylistContainer {
  display: flex;
  flex-direction: column;
  width: 70%;
  max-width: 1680px;
  margin: 1.5rem auto;
}

.innerPlaylistContainer.top {
  display: flex;
  align-items: flex-end;
  gap: 2.5rem;
  margin-bottom: 2rem;
}

.playlistMenu {
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  width: 30%;
}

.playlistImage {
  vertical-align: bottom;
  height: 270px;
  width: 270px;
}

.singlePlaylistTitle {
  margin: 0 0 0.01rem 0;
  font-size: 1.8rem;
  font-weight: 500;
  text-align: left;
}

.playlistDesc {
  margin-top: 0.2rem;
}

table {
  border-collapse: collapse;
}

#song-column,
#artist-column,
#album-column {
  font-size: 1.1rem;
  text-align: left;
}

#artist-column,
#album-column {
  font-size: 1.1rem;
  text-align: left;
}


#likes-column {
  width: 4%;
}

td {
  font-size: 1rem;
  padding: 0.1rem;
  border-style: hidden !important;
}

.song-title-td {
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
}

.playlistButtons {
  display: flex;
  gap: 10px;
  justify-self: flex-end;
}

.playlistButton {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 0.7rem;
  font-weight: 600;
  letter-spacing: 0.1em;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  background-color: rgb(187, 125, 11);
}
.playlistButton:hover {
  cursor: pointer;
}

.fa-play.orange,
.fa-pause.orange {
  color: rgb(187, 125, 11);
}

/*===========================*/

.song-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1680px;
}

.pepper {
  flex-shrink: 0;
  flex-grow: 1;
}

.categories {
  display: flex;
  justify-content: space-between;
  /* flex-direction: column; */
}

.song-item {
  display: flex;
  gap: 10px;
  /* align-items: center; */
  /* justify-content: space-between; */
  width: 100%;
  padding-left: 1rem;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.song-data-tr{
  padding: 50px;
}

.song-data-tr.grey {
  background-color: rgba(255, 255, 255, 0.1);
}

#pink {
  color: pink;
}

#blue {
  color: blue;
}

.song-data-tr:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.play-button,
.like-button {
  margin-left: 10px;
  margin-right: 10px;
}

.play-button {
  border: red solid 15px;
}

.play-pause-btn {
  cursor: pointer;
}

.thumbButtonContainer {
  flex-grow: 0;
  margin: 0.8rem;
  height: 100%;
  box-sizing: border-box;
  /* background-color: red; */
}

.playlistId-button-edit,
.playlistId-button-delete {
  /* border: red 12px solid; */
  /* background-color: transparent; */
  margin-top: 15px;
  margin-right: 15px;
  padding: 10px 20px;
  background-color: rgb(187, 125, 11);
  border-radius: 4px;
  color: white;
  border: none;
  width: 150px;
}

.more-options-container {
  position: relative;
  margin-left: auto;
}

.more-options {
  color: white;
  background-color: #2e2e2f;
  height: 30px;
  width: 30px;
  border: none;
  border-radius: 25px;
}

.song-options {
  color: white;
  
  height: 30px;
  width: 30px;
  border: none;
  border-radius: 25px;
}

.more-options-dropdown {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #252526;
  position: absolute;
  top: 125%;
  font-size: 0.8rem;
  font-weight: 500px;
  border-radius: 10px;
  height: auto;
  width: 80px;
  overflow: hidden;
  z-index: 1;
}

.edit-delete-container:hover {
  background-color: #2e2e2f;
}

.edit-delete {
  display: flex;
  justify-content: space-between;
  margin: 0 10px;
  box-sizing: border-box;
  align-items: center;
  height: 40px;
  cursor: pointer;
}

.hidden {
  display: none;
}

.add-button {
  display: flex;
  justify-content: center;
}


th{
  padding-bottom: 5px;
}