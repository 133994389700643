.artist-page {
  padding: 20px;
}

.artist-name {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.section-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.album-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.album-cover {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 5px;
}

.song-list {
  margin-top: 10px;
}

.song-title {
  font-size: 16px;
  margin-bottom: 5px;
}
