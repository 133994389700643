.outerAlbumContainer {
  display: flex;
  flex-direction: column;
  width: 70%;
  max-width: 1680px;
  margin: 1.5rem auto;
}

.innerAlbumContainer.top {
  display: flex;
  align-items: flex-end;
  gap: 2.5rem;
  margin-bottom: 2rem;
}
.albumMenu {
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  width: 30%;
}
.albumImage {
  vertical-align: bottom;
  height: 270px;
  width: 270px;
}
.albumTitle {
  margin: 0 0 0.01rem 0;
  font-size: 1.75rem;
  font-weight: 600;
}

.albumArtist a {
  color: rgb(187, 125, 11);
  font-size: 1.5rem;
  font-weight: 400;
  text-decoration: none;
}

.albumArtistById {
  color: rgb(187, 125, 11);
  font-size: 1.5rem;
  font-weight: 400;
  text-decoration: none;
}

.descContainer {
  display: flex;
  max-height: 75px;
  margin-bottom: 2rem;
  overflow: hidden;
}

.descContainer:after {
  content: attr(data-tooltip);
  position: absolute;
  font-size: 0.7rem;
  bottom: 1px;
  right: 1px;
  box-shadow: 0px -5px 10px rgba(22, 22, 23, 1);
  background-color: rgba(22, 22, 23, 1);
}

.albumDesc {
  text-align: start;
  font-size: 0.9rem;
}

.albumGenreYear {
  display: flex;
  padding: 0px;
}

.albumGenreYear > p {
  margin-top: 0.2rem;
  font-size: 0.8rem;
}

#dot {
  margin-left: 0.3rem;
  margin-right: 0.3rem;
}

.orangeButtons {
  display: flex;
  gap: 10px;
  justify-self: flex-end;
}

.orangeButton {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 0.7rem;
  font-weight: 600;
  letter-spacing: 0.1em;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  background-color: rgb(187, 125, 11);
}

.fa-play.orange {
  color: rgb(187, 125, 11);
}

/*===========================*/

.song-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1680px;
}
.song-title {
  display: flex;
  font-size: 0.9rem;
  gap: 2rem;
  color: white;
}
.song-data {
  display: flex;
  justify-content: space-between;
}

.pepper {
  flex-shrink: 0;
  flex-grow: 1;
}

.song-item {
  width: 100%;
  padding-left: 1rem;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.song-data.grey {
  background-color: rgba(255, 255, 255, 0.1);
}

#pink {
  color: pink;
}

#blue {
  color: blue;
}

.song-item:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
.play-button,
.like-button {
  margin-left: 10px;
  margin-right: 10px;
}

.play-button {
  border: red solid 15px;
}

.play-pause-btn {
  cursor: pointer;
}

.thumbButtonContainer {
  flex-grow: 0;
  margin: 0.8rem;
  height: 100%;
  box-sizing: border-box;
}

.update-button,
.delete-button {
  background-color: rgb(187, 125, 11);
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 14px;
  border-radius: 4px;
  margin: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.plus-sign.album {
  height: 10px;
  width: 10px;
  border-radius: 30px;
  filter: invert(1);
}

.play-button-td {
  width: 30px;
  padding-left: 10px;
  padding-right: 15px;
  text-align: center;
}

.likes-td {
  text-align: center;
  width: 35px;
}

.add-to-list-td {
  text-align: center;
  width: 35px;
}

.options-td {
  text-align: center;
  width: 35px;
}

.remove-song-td {
  text-align: center;
  width: 35px;
}

