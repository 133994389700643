.new-playlist-modal {
  width: 700px;
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  margin: 0 auto;
  overflow: none;
}

.preview-cover{
  vertical-align: bottom;
}

.oneLiner {
  min-width: 0;
  width: 100%;
  margin-bottom: 5px;
}

.flex-inputs {
  display: flex;
  gap: 15px;
  margin-top: 15px;
  width: 100%;
}

.cityLabel {
  flex-basis: 60%;
}

.cityLabel > input {
  width: 100%;
}

.stateLabel {
  flex-basis: 40%;
}

.stateLabel > input {
  width: 100%;
}

.priceLabel {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.images {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.lines.form {
  margin-top: 20px;
  margin-bottom: 10px;
}

.buttonContainer {
  display: flex;
  justify-content: center;
}
.pinkButton.create,
.pinkButton.edit {
  margin: 10px;
  width: 200px;
}

.errors {
  color: red;
  font-weight: bold;
}

.textArea {
  resize: none;
  width: 100%;
  height: 50px;
}

.oneLiner,
.textArea {
  margin-top: 15px;
}
