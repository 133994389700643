.signup-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    max-width: 350px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 20px orange;
    background-color: rgb(187, 125, 11);
    transition: box-shadow 0.2s ease;
  }

  /* .signup-form input{
    border-radius: 10px;
  } */
  /* .signup-form:hover {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  } */

  .signup-form-header {
    text-align: center;
    font-size: 2.5em;
    color: #333;
    margin-bottom: 20px;
  }

  .signup-form-errors {
    display: flex;
    justify-content: flex-start;
    list-style: none;
    padding: 0;
    margin-bottom: 20px;
    width: 100%;
    margin-top: 15px;
    text-align: center;
  }

  .signup-form-error {
    display: flex;
    justify-content: flex-start;
    color: white;
    margin-bottom: 10px;
  }

  .signup-form-label {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
    font-size: 1em;
    color: #333;
  }

  /* .signup-form-label:focus {
    box-shadow: 0px 0px 10px white;
    border: 1px red solid;
  } */

  .signup-form-input {
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ccc;
    outline: none;
    font-size: 1em;
    transition: border-color 0.2s ease;
  }


  .signup-form-button {
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    background-color: #06660b7c;
    color: #ffffff;
    font-size: 1em;
    cursor: pointer;
    width:100%;
    transition: background-color 0.2s ease;
  }

  .signup-form-button:hover {
    background-color: #06660bfd;
  }
