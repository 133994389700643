/* other css */

a {
    text-decoration: none;
    color: white;
}

Link {
    text-decoration: none;
}


html{
    font-family: 'Source Sans Pro', sans-serif;
    text-decoration: none;
}

body {
    font-family: 'Source Sans Pro', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: rgba(238, 238, 238, 1);
}

h1,h2,h3,h4{
    margin: 0;
}

button {
    cursor: pointer;
}

.site-wrapper {
    display: flex;
    justify-content: space-between;
    max-width: 100vw;
    height: 100vh;
    border: 1px solid rgba(64, 64,64, 1);
}

.nav-list-wrapper {
    width: 20%;
    height: auto;
    background-color: rgba(37, 37, 38, 1);
}

.site-wrapper-right {
    width: 85%;
    margin-top: -10px;
    row-gap: 25px;
    justify-content: flex-start;
    background-color: rgba(22, 22, 23, 1);
    align-items: center;
    overflow-y: scroll;
    border: 1px solid rgba(64, 64,64, 1);
}

.site-wrapper-right-top,
.main-component {
    align-self: center;
    min-height: fit-content;
    box-sizing: border-box;
}

.main-component {
    overflow-y: auto;
}

.site-wrapper-right-top {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: rgba(44, 44, 44, 1);
    padding: 30px;
}

.audio-player-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    background-color: rgba(31, 31,31, 1);
    padding-right: 10px;
}

.audio-player-image{
    margin: auto;
}

::-webkit-scrollbar {
    display: none
}

.disabled-mouse:hover{
    cursor: not-allowed;
}

.blue-button-square{
    background-color: #0070f3;
    color: white;
    border: 1px solid transparent;
    border-radius: 4px;
    padding: 10px 20px;
    width: 150px;
}

.blue-button-square:hover{
    background-color: #06660bfd;
}

.green-hover:hover{
    background-color: #06660bfd;
}

.red-hover:hover{
    background-color: red;
}
