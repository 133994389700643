.mobile-view-message {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    color: black;
    font-weight: bold;
    padding: 40px;
    z-index: 100000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000;
  }
