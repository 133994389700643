.songForm{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}


.titleInput, .genreInput, .fileInput{
    margin-left: .8rem;
    color: white;
    background-color: #2e2e2f;
}

input::file-selector-button {
    font-weight: bold;
    color: #fd552b;
    padding: 0.5em;
    border: thin solid grey;
    border-radius: 3px;
}

/*----- Modal CSS -------*/

.btn-modal {
    padding: 10px 20px;
    display: block;
    margin: 100px;
    font-size: 18px;
}



.overlay{
    width: 100vw;
    height: 100vw;
    left: 50%;
    bottom:50%;
    transform: translate(-50%, 50%);
    background-color: rgba(49,49,49,0.8);
    position: fixed;

}